@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
}



.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorError.MuiIconButton-sizeMedium.delete-icon:hover {
  background-color: rgba(220, 220, 220, 0.8);
}

.portfolio-image:hover {
  filter: blur(2px);
}


::-webkit-scrollbar {
  width: 0px;
  height: 9px;
}


::-webkit-scrollbar-thumb {
  background-color: #096c45;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

